import React from 'react'
import './services.css'
import { IoSchoolSharp } from "react-icons/io5"
import { MdOutlineSchedule } from "react-icons/md"

const Services = () => {
  return (
    <section id='services'>
      <h5>Education</h5>
      <h2>My Education So Far...</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3><IoSchoolSharp className="service__education-icon" />  Toronto Metropolitan University (Formerly Ryerson University)</h3>
          </div>

          <ul className="service__list">
            <li>
              <MdOutlineSchedule className="service__list-icon" />
              <p>Bachelor of Science (Psychology)</p>
              <p>2017 - 2022</p>
            </li>
          </ul>
        </article>
        
        <article className="service">
          <div className="service__head">
            <h3><IoSchoolSharp className="service__education-icon" />  George Brown College</h3>
          </div>

          <ul className="service__list">
            <li>
              <MdOutlineSchedule className="service__list-icon" />
              <p>Human Resource Management</p>
              <p>2023 - 2024 (Expected)</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services