import React from 'react'
import './contact.css'
import { MdOutlineMail } from 'react-icons/md'
import { BsLinkedin } from 'react-icons/bs'

const Contact = () => {
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>waseelah.maryam@gmail.com</h5>
            <a href="mailto:waseelah.maryam@gmail.com" target="_blank">Send An Email</a>
          </article>
          <article className="contact__option">
            <BsLinkedin className="contact__option-icon"/>
            <h4>LinkedIn</h4>
            <h5>Waseelah Maryam</h5>
            <a href="https://www.linkedin.com/in/waseelah-maryam-9280551b2/" target="_blank">Send An Message</a>
          </article>
        </div>

        <form action="#">
          <input type="text" name="name" id="name" placeholder="Your Full Name" required/>
          <input type="email" name="email" id="email" placeholder="Your Email" required/>
          <textarea name="message" id="message" rows="7" placeholder="Your Message" required></textarea>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact