import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Experiences I Have</h5>
      <h2>My Experiences</h2>

      <div className="container experience__container">
        <div className="experience__one">
          <h3>Vaccine Scheduler<br></br><small>Public Health Ontario</small></h3>
          
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Advanced Customer Service</h4>
                <small className="text-light">Assisted clients in information, technical and scheduling needs</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Virtual Client Interaction</h4>
                <small className="text-light">Interacting with a diverse range of clients over the phone via incoming calls in a dynamic work environment</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Tools: Integrated Booking, Canceling, Confirming</h4>
                <small className="text-light">Using integrated tools such as Sales Force, COVaxON, and Amazon Connect to book, cancel, and confirm appointments</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Administrative Tasks</h4>
                <small className="text-light">Executing checklists, documenting client requirements, and engaging with team members through email and Microsoft Teams</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Dynamic Performance</h4>
                <small className="text-light">Participated in compulsory training sessions upon the introduction of new features in COVaxON; Adapted operations in alignment with the vaccine scheduling standards set by Public Health Ontario</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Client Assistance</h4>
                <small className="text-light">Provided support to clients in resolving vaccine booking requirements and technical issues encountered on the web application; Effectively guided clients to the appropriate agents capable of delivering the necessary assistance</small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__two">
          <h3>Receptionist<br></br><small>Search Realty</small></h3>
          
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>MS Office Proficiency</h4>
                <small className="text-light">Proficient in Microsoft Office suite, including Excel, Word, Outlook, and PowerPoint</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Advanced Customer Interaction</h4>
                <small className="text-light">Demonstrated strong customer service skills; effectively collaborating with internal and external agents</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Virtual Client Interaction</h4>
                <small className="text-light">Interacting with a diverse range of clients over the phone via incoming calls in a dynamic work environment</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Real Estate Administration</h4>
                <small className="text-light">Performing administrative tasks pertaining to real estate listings, such as checklist completion, property loading, cancellation and relisting, and communication with team members via email</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Comparative Marketing Analysis</h4>
                <small className="text-light">Preparing Comparative Marketing Analysis binders for agents</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Catalog inventory management</h4>
                <small className="text-light">Conducting catalog inventory and coordinating pick-up and delivery of property catalogs</small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__three">
          <h3>Vaccine Clinic Support<br></br><small>Peel Region</small></h3>
            
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Clinic Support Staff</h4>
                <small className="text-light">Appointed as a valuable member of the support staff at various clinics within the Halton region</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Versatile Administrative Roles</h4>
                <small className="text-light">Assumed diverse responsibilities as directed by the administrative coordinator, encompassing roles such as screener, registration clerk, navigator, and check-out clerk</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Efficient Client Registration</h4>
                <small className="text-light">Proficiently registered clients using the provincial COVAX application, meticulously verifying their personal information and adhering to clinic guidelines for check-in procedures</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Accurate Check-Out Process</h4>
                <small className="text-light">Facilitated the check-out process for clients by furnishing them with a comprehensive vaccine receipt and pertinent details regarding their subsequent appointment. Skillfully updated client information and dosage administration data within the Covax application</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Collaborative Teamwork</h4>
                <small className="text-light">Collaborated seamlessly within both team environments and while working independently</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Effective Communication Coordination</h4>
                <small className="text-light">Maintained regular communication with the clinic supervisor and administrative coordinators, effectively addressing any concerns, issues, or discrepancies that arose</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Informative Client Assistance</h4>
                <small className="text-light">Offered prompt and accurate responses to clients' inquiries concerning clinic operations or vaccination protocols</small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__four">
          <h3>Student Engagement Coordinator<br></br><small>Ryerson Student Union</small></h3>
            
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Executive Administrative Support</h4>
                <small className="text-light">Delivering high-level administrative support to the Office of Admissions, catering to the needs of executives</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Student Guidance Facilitation</h4>
                <small className="text-light">Collaborating with a diverse student population, offering comprehensive guidance in navigating program and course selection</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Multichannel Support Coordination</h4>
                <small className="text-light">Providing adept support and assistance through various channels including email, telephone, and face-to-face interactions</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Document Preparation</h4>
                <small className="text-light">Meticulously preparing and editing documents in accordance with precise instructions provided by the Office of Admissions</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>MS Office Proficiency</h4>
                <small className="text-light">Demonstrating proficiency in utilizing computer software such as Word, Excel, and PowerPoint to streamline tasks and enhance productivity</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Database Management</h4>
                <small className="text-light">Ensuring accurate and up-to-date information in the company database, while diligently working within tight college admission deadlines</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Meticulous Information Processing</h4>
                <small className="text-light">Exercising keen attention to detail and information to guarantee precision and quality in all undertaken responsibilities</small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__five">
          <h3>Teaching Assistant<br></br><small>ICC Summer Camp</small></h3>
            
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Classroom Support Coordination</h4>
                <small className="text-light">Provided valuable support in classroom operations, assisting the head teacher in effectively managing a diverse student body with various backgrounds and ethnicities</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Exam Supervision</h4>
                <small className="text-light">Facilitated and supervised student activities under the guidance of the teacher</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Parental Engagement Facilitation</h4>
                <small className="text-light">Fostered positive collaboration with parents to ensure a satisfactory learning experience and engaged in constructive discussions regarding camp progress</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience