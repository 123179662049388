import React from 'react'
import './about.css'
import ME from '../../assets/linkedinimagewaseelah.jpg'
import { FaAward } from 'react-icons/fa'
import { HiOutlineUsers } from 'react-icons/hi'
import { MdOutlineVolunteerActivism } from 'react-icons/md'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-img">
            <img src={ ME } alt="About Me" />
          </div>
        </div>

        <div className="about__content">
        <div className="about__cards">
          <article className="about__card">
            <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>4+ Years Of Experience</small>
          </article>

          <article className="about__card">
            <HiOutlineUsers className='about__icon'/>
            <h5>Clients / Customers</h5>
            <small>200+ Countrywide</small>
          </article>

          <article className="about__card">
            <MdOutlineVolunteerActivism className='about__icon'/>
            <h5>Volunteering</h5>
            <small>6+ Years Of NPO Volunteering</small>
          </article>
        </div>
        <p>
          Information About Me :)
        </p>

        <a href="#contact" className="btn btn-primary">Contact Me</a>
      </div>

      
      </div>
    </section>
  )
}

export default About