import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { CgWebsite } from 'react-icons/cg'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/waseelah-maryam-9280551b2/" target="_blank"><BsLinkedin /></a>
        <a href="https://waseelahmaryam.com" target="_blank"><CgWebsite /></a>
    </div>
  )
}

export default HeaderSocials