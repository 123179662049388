// Call To Action (CTA) component for buttons

import React from 'react'
// import CV from '../../assets/resume.pdf'

const CTA = () => {
  return (
    <div>
        <div className="cta">
            <a /* href={ CV } download */ className='btn'>Download CV</a>
            <a href="#contact" className='btn btn-primary'>Contact</a>
        </div>
    </div>
  )
}

export default CTA