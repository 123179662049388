import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/linkedinimagewaseelah.jpg'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
        <div className="container header__container">
            <h5>Hello, I am</h5>
            <h1>Waseelah Maryam</h1>
            <h5 className="text-light">Administrative Support Specialist</h5>
            <CTA />

            <HeaderSocials />

            <div className="me">
                <img src={ ME } alt="Waseelah Maryam Introduction" />
            </div>

            <a href="#contact" className='scroll__down'>Scroll Down</a>
        </div>
    </header>
  )
}

export default Header