import React from 'react'
import './testimonials.css'

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Reviews From E</h5>
      <h2>My Experiences</h2>
    </section>
  )
}

export default Testimonials