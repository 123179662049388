import React from 'react'
import './nav.css'
import { BiHomeAlt } from 'react-icons/bi'
import { BiUserCircle } from 'react-icons/bi'
import { CgWorkAlt } from 'react-icons/cg'
import { RiServiceLine } from 'react-icons/ri'
import { AiOutlineContacts } from 'react-icons/ai'

import { useState } from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="#" onClick={ () => setActiveNav('#') } className={ activeNav === '#' ? 'active' : ''}><BiHomeAlt /></a> {/* Home */}
      <a href="#about" onClick={ () => setActiveNav('#about') } className={ activeNav === '#about' ? 'active' : '' }><BiUserCircle /></a> {/* About */}
      <a href="#experience" onClick={ () => setActiveNav('#experience') } className={ activeNav === '#experience' ? 'active' : '' }><CgWorkAlt /></a> {/* Experiences */}
      {/* <a href="#portfolio" onClick={ () => setActiveNav('#portfolio') } className={ activeNav === '#portfolio' ? 'active' : '' }><RiServiceLine /></a> */} {/* Qualifications */}
      <a href="#contact" onClick={ () => setActiveNav('#contact') } className={ activeNav === '#contact' ? 'active' : '' }><AiOutlineContacts /></a> {/* Contact */}
    </nav>
  )
}

export default Nav